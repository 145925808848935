import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Moment from "react-moment";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BlogGrid from "../components/blog-grid";
import BlogTags from "../components/blog-tags";

const Blog = ({ data, pageContext }) => {
  const { nodes: posts } = data.allBlogs;
  const { tag } = pageContext;
  return (
    <Layout>
      <h1 className="font-serif text-6xl text-center mb-8">Blog/{tag}</h1>
      <BlogTags currentTag={tag} />
      <BlogGrid posts={posts} />
    </Layout>
  );
};

export const query = graphql`
  query BlogPostsByTagUid($uid: String!) {
    allBlogs: allPrismicBlogPost(
      filter: { data: { tags: { elemMatch: { tag: { uid: { eq: $uid } } } } } }
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        data {
          date
          title {
            text
          }
          subtitle {
            text
          }
          featured_image {
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
          post_body {
            text
          }
          tags {
            tag {
              document {
                ... on PrismicTag {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`;

export default Blog;
